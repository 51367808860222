import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { createRef, useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Footer from '../sections/Footer/Footer';
import ScrollTop from '../components/ScrollTop/ScrollTop';
import Navbar from '../components/Navbar/Navbar';
import HeroText from '../sections/HeroText/HeroText';
import Creator from '../components/Creator/Creator';
import entryAnimation from '../resources/entry-animation';

const CreatorWrapper = (creator) => {
    gsap.registerPlugin(ScrollTrigger);
    const creatorRef = createRef();
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.fromTo(creatorRef.current, { scale: 0.1, opacity: 0 }, {
            scale: 1,
            opacity: 1,
            duration: 1,
            ease: 'Power2.easeOut',
            scrollTrigger: {
                trigger: creatorRef.current,
                start: 'top bottom',
            },
        });
    });

    return (
        <Creator ref={creatorRef} {...creator} key={creator.id} />
    );
};

const OurCreatorsPage = () => {
    gsap.registerPlugin(ScrollTrigger);
    const navbarBreakpointRef = useRef();
    const data = useStaticQuery(query);
    const mainSettings = data.allGetHeroMainSettings.nodes[0];
    const mainRef = useRef(null);
    entryAnimation(mainRef, true);
    useEffect(() => {
        const shuffle = () => {
            const creators = document.getElementById('creators');
            let creatorsItems = [].slice.call(creators.getElementsByClassName('creator-component'));
            creatorsItems = creatorsItems.sort(() => Math.random() - 0.5);
            creators.innerHTML = '';
            creatorsItems.forEach((item) => {
                creators.appendChild(item);
            });
            creators.style.display = 'flex';
        };
        if (document.readyState === 'complete') {
            shuffle();
        } else {
            window.addEventListener('load', shuffle);
        }
    }, []);
    return (
        <main ref={mainRef} className="our-creators">
            <div ref={navbarBreakpointRef} className="ref-navbar" />
            <Navbar
                breakpointRef={navbarBreakpointRef}
                logo={mainSettings.logo[0] ? mainSettings.logo[0].localFile.publicURL : ''}
                smallLogo={mainSettings.small_logo[0] ? mainSettings.small_logo[0].localFile.publicURL : ''}
            />
            <Helmet>
                <title>NASI TWÓRCY | GetHero</title>
            </Helmet>
            <HeroText
                header={data.allCreatorsPage.nodes[0].header}
                firstParagraph={data.allCreatorsPage.nodes[0].first_hero_paragraph}
                secondParagraph={data.allCreatorsPage.nodes[0].second_hero_paragraph}
            />

            <div className="our-creators__list" id="creators">
                {
                    data.allCreator.nodes.map((creator) => CreatorWrapper(creator))
                }
            </div>
            <Footer />
            <ScrollTop />
        </main>
    );
};

const query = graphql`
    query OurCreatorsSettings {
      allCreator {
        nodes {
          id
          name
          image {
            localFile {
              publicURL
            }
          }
          instagram_link
          tiktok_link
          youtube_link
          type
        }
      }
      allGetHeroMainSettings {
        nodes {
          menu {
            name
            main
            additional
            link
          }
          logo {
            localFile {
              publicURL
            }
          }
          small_logo {
            localFile {
              publicURL
            }
          }
        }
      }
      allCreatorsPage {
        nodes {
          first_hero_paragraph
          header
          second_hero_paragraph
        }
      }
    }`;

export default OurCreatorsPage;
