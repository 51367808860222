import './Creator.scss';
import React, { forwardRef } from 'react';

// creator is spread, properties in Flotiq object are with underscores
// eslint-disable-next-line camelcase
const Creator = forwardRef(({ type, name, image, youtube_link, instagram_link, tiktok_link }, ref) => (
    <div ref={ref} className="creator-component">
        <div className="creator-component__image-container">
            <span className="creator-component__type">
                #
                {type}
            </span>
            <div className="img-wrap">
                <div
                    className="img"
                    style={{
                        backgroundImage: `url(${image[0] ? image[0].localFile.publicURL : ''})`,
                    }}
                />
            </div>
        </div>
        <h4 className="creator-component__name">{name}</h4>
        <p className="creator-component__social">
            {youtube_link && (
                <a
                    href={`https://youtube.com/${youtube_link}`}
                    className="creator-component__social__link"
                    target="_blank"
                    rel="noreferrer"
                >
                    <em className="icon youtube-icon" />
                </a>
            )}
            {instagram_link && (
                <a
                    href={`https://instagram.com/${instagram_link}`}
                    className="creator-component__social__link"
                    target="_blank"
                    rel="noreferrer"
                >
                    <em className="icon instagram-icon" />
                </a>
            )}
            {tiktok_link && (
                <a
                    href={`https://tiktok.com/${tiktok_link}`}
                    className="creator-component__social__link"
                    target="_blank"
                    rel="noreferrer"
                >
                    <em className="icon tiktok-icon" />
                </a>
            )}
        </p>
    </div>
));

export default Creator;
